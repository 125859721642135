import { Link } from "gatsby";
import React from "react"
import Layout from "../components/Layout"
import notFoundImage from "../../static/icons/404.svg";

const PageNotFound = () => {
  return (
    <Layout>
      <div className="container-fluid">
        <div className="spacer"></div>
        <div className="spacer"></div>
        <div className="row justify-content-center">
          <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4">
            <div className="d-flex flex-column align-items-center">
              <img src={notFoundImage} className="w-100" alt="error404"/>
              <div className="spacer-text"></div>
              <div className="spacer-text"></div>
              <h2 className="bt-1 text-center text-light">Oops, we can't find the page you're looking for.</h2>
              <div className="spacer-text"></div>
              <Link to="/">
                <div className="cta-primary">
                  <p>RETURN HOME</p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="spacer"></div>
        <div className="spacer"></div>
      </div>
    </Layout>
  )
}

export default PageNotFound
